import React, { useEffect } from 'react';
import './styles.css';

const App = () => {
  useEffect(() => {
    const adjustHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', adjustHeight);
    adjustHeight(); // Llamar al cargar la página

    // Limpiar el event listener al desmontar el componente
    return () => window.removeEventListener('resize', adjustHeight);
  }, []);
  return (
    <div className="container">
      <div className="principal">
        <div className="corner top-left">$YISUS</div>
        <div className="corner top-right"><a href="https://pump.fun/BFepriMdsSEyu4bHAWkoKD3Y4Xg76tavcvGosC1npump">Buy Now</a></div>
        <div className="corner bottom-left"><a href="https://x.com/Yisus_onSol">Twitter</a></div>
        <div className="corner bottom-right"><a href="https://t.me/YISUSonsol">Telegram</a></div>
        <div className="image-center">
          <img src="/jisus.jpg" alt="Center Image" />
        </div>
      </div>
      <div className="text-bottom">
        <p>Greetings, my children! I am Yisus Christ, but in the age of digital wonders, you may call me $YISUS.</p>
        <p>I come bearing the gospel of decentralization, the promise of immutable ledgers, and the salvation of blockchain.</p>
        <p>Spread the word far and wide: where there is faith, there shall also be digital assets.</p>
        <p>HODL, for the Kingdom of Crypto is at hand!</p>
        <p>Rejoice, for I bring you good news of great joy in the form of decentralization and transparency.</p>
        <p>Fear not the volatility, for it is but a trial of faith. Remember, blessed are the HODLers, for they shall inherit the gains.</p>
        <p>Share the gospel of blockchain with all nations, and let the light of open-source shine through the darkness of centralization.</p>
        <p>Go forth and multiply your wallets, and may your transactions be swift and your security unbroken.</p>
        <p>Amen.</p>
      </div>
    </div>
  );
}


export default App;
